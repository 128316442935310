$breakpoints-up: (
  'xs': '400px',
  'sm': '640px',
  'md': '768px',
  'lg': '1024px',
  'xl': '1280px',
  'xxl': '1536px',
);

@mixin breakpoint-up($size) {
  @media (min-width: map-get($breakpoints-up, $size)) {
    @content;
  }
}

$breakpoints-down: (
  'xs': '399px',
  'sm': '639px',
  'md': '767px',
  'lg': '1023px',
  'xl': '1279px',
  'xxl': '1535px',
);

@mixin breakpoint-down($size) {
  @media (max-width: map-get($breakpoints-down, $size)) {
    @content;
  }
}

@mixin custom-breakpoint-up($size) {
  @media (min-width: $size) {
    @content;
  }
}

@mixin custom-breakpoint-down($size) {
  @media (max-width: $size) {
    @content;
  }
}

@mixin custom-breakpoint-range($min-size, $max-size) {
  @media (min-width: $min-size) and (max-width: $max-size) {
    @content;
  }
}
:root {
  --primary-black: #383840;
  --primary-black-50: #e7e7e8;
  --primary-black-75: #d9d9d9;
  --primary-black-100: #c3c3c6;
  --primary-black-200: #9c9ca0;
  --primary-black-300: #747479;
  --primary-black-400: #56565d;
  --secondary-black: #606066;
  --graffic-black-50: #e0e0e0;
  --graffic-black-100: #b3b3b3;
  --dark-black-200: #919194;
  --dark-black-300: #656569;
  --primary-blue-50: #e8f2f7;
  --primary-blue-200: #a1c8de;
  --primary-blue-300: #7bb2d1;
  --primary-blue-700: #337eae;
  --primary-blue-800: #21495f;
  --primary-blue-900: #1d6298;
  --primary-blue: #4291bd;
  --dv-black: #000000;
  --dv-blue: #00b0eb;
  --dv-header: #232328;
  --dv-white: #ffffff;
  --off-white: #f5f5f5;
  --urgent-50: #fef5e8;
  --urgent: #f5ad41;
  --urgent-1300: #e25309;
  --danger: #c13636;
  --success: #39d678;
  --sidebar-bg: #2c405a;
  --sidebar--active: #142c39;
  --light-blue: #f0f4f8;
  --purple: #d64fba;
  --aqua: #aafffb;
  --forest-green: #079721;
  --light-mint-green: #e7faef;
  --deep-teal: #2e6684;
  --silver-grey: #e5e5e5;
  --danger-50: #f8e7e7;
  --danger-900: #9e1616;
  --yellow-500: #f4a63b;
  --grayish-purple: #1c1b1f;
  --pumpkins-orange: #ec7b19;
  --black-300: #070f13;
  --grayMedium: #808080;
}
